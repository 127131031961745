import React, { PropsWithChildren } from "react";

import classes from "./Body.module.css";

function Body(props: PropsWithChildren<{}>) {
  return (
    <div className={classes.body}>
      <div className={classes["body-content"]}>{props.children}</div>
    </div>
  );
}

export default Body;
