import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { variables } from "@liveperson-design-system/tokens";
import "@liveperson-design-system/icons/dist/settings";

import PayForm from "./PayForm/PayForm";
import { LPSDKContext } from "../store/lpsdk-context";
import TitleBar from "./layout/TitleBar/TitleBar";
import Body from "./layout/Body/Body";
import BottomBar from "./layout/BottomBar/BottomBar";
import { NavLink, Route, Routes } from "react-router-dom";
import Config from "./Config/Config";
import { Toaster } from "react-hot-toast";

function App() {
  const LPSDKCtx = useContext(LPSDKContext);

  return (
    <>
      <Helmet>
        <style>{`body {background-color: ${
          LPSDKCtx.theme == "light"
            ? variables.colors.navy.white
            : variables.colors.navy.dark
        }; color: ${LPSDKCtx.theme == "light" ? "black" : "white"}}`}</style>
      </Helmet>
      <TitleBar>Payment request</TitleBar>
      <Body>
        <Routes>
          <Route path="/" element={<PayForm />} />
          <Route path="/config" element={<Config />} />
        </Routes>
      </Body>
      <BottomBar>
        <NavLink
          to="/config"
          style={{ color: LPSDKCtx.theme == "light" ? "black" : "white" }}
        >
          <lp-settings-icon />
        </NavLink>
      </BottomBar>
      <Toaster />
    </>
  );
}

export default App;
