import React, { useContext, useRef, MouseEvent, useState } from "react";
import {
  LpTextInput,
  LpInputLabel,
  LpButton,
  LpInputHelper,
  LpRadioGroup,
  LpRadio,
} from "@liveperson-design-system/react-components";

import { LPSDKContext } from "../../store/lpsdk-context";
import classes from "./PayForm.module.css";
import Card from "../layout/Card/Card";
import ReactCountryFlag from "react-country-flag";
import { SpinnerCircular } from "spinners-react";
import { ConfigContext } from "../../store/config-context";
import toast from "react-hot-toast";

function PayForm() {
  const amountInputRef = useRef<HTMLLpTextInputElement>(null);
  const [currency, setCurrency] = useState<string>("");
  const currencyInputRef = useRef<HTMLLpRadioGroupElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [amountError, setAmountError] = useState(false);
  const [currencyError, setCurrencyError] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const LPSDKCtx = useContext(LPSDKContext);
  const ConfigCtx = useContext(ConfigContext);

  function onRGChangeHandler(event: any) {
    1 + 1; // In order to React execute the next line of code, the function needs to do something...
    setCurrency(event.target.value);
  }

  async function onClickHandler(event: MouseEvent<HTMLLpButtonElement>) {
    event.preventDefault();

    let error = false;
    setBackendError(false);
    if (
      isNaN(Number(amountInputRef.current?.value)) ||
      Number(amountInputRef.current?.value) < 1
    ) {
      setAmountError(true);
      error = true;
    } else {
      setAmountError(false);
    }

    if (!currency || currency === "") {
      setCurrencyError(true);
      error = true;
    } else {
      setCurrencyError(false);
    }

    if (error || LPSDKCtx.account === 0 || LPSDKCtx.conversationId === "") {
      return;
    } else {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/session/${LPSDKCtx.account}`,
        {
          method: "POST",
          body: JSON.stringify({
            liveperson: {
              conversationId: LPSDKCtx.conversationId,
              customisation: {
                title: ConfigCtx.title,
                message: ConfigCtx.message,
                buttonText: ConfigCtx.buttonText,
              },
            },
            purchase: {
              items: [
                {
                  name: "On demand payment",
                  price: Number(amountInputRef.current?.value),
                },
              ],
              currency,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        amountInputRef.current ? (amountInputRef.current.value = "") : null;
        currencyInputRef.current
          ? (currencyInputRef.current.selected = "")
          : null;
        setCurrency("");
        setLoading(false);
        toast.success(ConfigCtx.successMessage);
      } else {
        setLoading(false);
        setBackendError(true);
        toast.error(ConfigCtx.errorMessage);
      }
    }
  }

  return (
    <Card>
      <form className={classes["main-form"]}>
        <div>
          <LpInputLabel theme={LPSDKCtx.theme}>Amount</LpInputLabel>
          <LpTextInput
            theme={LPSDKCtx.theme}
            ref={amountInputRef}
            inputMode="numeric"
          />
          {amountError && (
            <LpInputHelper error theme={LPSDKCtx.theme}>
              Amount needs to be a number larger than 1
            </LpInputHelper>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <LpInputLabel theme={LPSDKCtx.theme}>Currency</LpInputLabel>
          <LpRadioGroup
            name="currency"
            theme={LPSDKCtx.theme}
            size="large"
            ref={currencyInputRef}
          >
            <LpRadio name="€" value="EUR" onMouseDown={onRGChangeHandler}>
              <ReactCountryFlag countryCode="EU" style={{ fontSize: "2em" }} />
            </LpRadio>
            <LpRadio name="£" value="GBP" onMouseDown={onRGChangeHandler}>
              <ReactCountryFlag countryCode="GB" style={{ fontSize: "2em" }} />
            </LpRadio>
            <LpRadio name="$" value="USD" onMouseDown={onRGChangeHandler}>
              <ReactCountryFlag countryCode="US" style={{ fontSize: "2em" }} />
            </LpRadio>
          </LpRadioGroup>
          {currencyError && (
            <LpInputHelper error theme={LPSDKCtx.theme}>
              Please select a valid currency
            </LpInputHelper>
          )}
        </div>
        <LpButton
          theme={LPSDKCtx.theme}
          onClick={onClickHandler}
          style={{ marginTop: "20px" }}
        >
          {!loading && "Send request"}
          <SpinnerCircular enabled={loading} size="20px" />
        </LpButton>
        {backendError && (
          <LpInputHelper error theme={LPSDKCtx.theme}>
            Error contacting the payment orchestrator
          </LpInputHelper>
        )}
      </form>
    </Card>
  );
}

export default PayForm;
