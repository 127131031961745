import React, { createContext, useEffect, useState } from "react";

type ConfigContextObj = {
  title: string;
  message: string;
  buttonText: string;
  successMessage: string;
  errorMessage: string;
  changeTitle: (title: string) => void;
  changeMessage: (message: string) => void;
  changeButtonText: (buttonText: string) => void;
  changeSuccessMessage: (successMessage: string) => void;
  changeErrorMessage: (errorMessage: string) => void;
  resetToDefault: () => void;
};

const DEFAULT_TITLE = "Pay now";
const DEFAULT_MESSAGE = "Please click the link to pay now";
const DEFAULT_BUTTON_TEXT = "Pay now";
const DEFAULT_SUCCESS_MESSAGE = "Payment request sent!";
const DEFAULT_ERROR_MESSAGE = "Error while sending the payment request.";

export const ConfigContext = createContext<ConfigContextObj>({
  title: DEFAULT_TITLE,
  message: DEFAULT_MESSAGE,
  buttonText: DEFAULT_BUTTON_TEXT,
  successMessage: DEFAULT_SUCCESS_MESSAGE,
  errorMessage: DEFAULT_ERROR_MESSAGE,
  changeTitle: (title: string) => {},
  changeMessage: (message: string) => {},
  changeButtonText: (buttonText: string) => {},
  changeSuccessMessage: (successMessage: string) => {},
  changeErrorMessage: (errorMessage: string) => {},
  resetToDefault: () => {},
});

function ConfigContextProvider(props: { children: any }) {
  const [title, setTitle] = useState<string>(DEFAULT_TITLE);
  const [message, setMessage] = useState<string>(DEFAULT_MESSAGE);
  const [buttonText, setButtonText] = useState<string>(DEFAULT_BUTTON_TEXT);
  const [successMessage, setSuccessMessage] = useState<string>(
    DEFAULT_SUCCESS_MESSAGE
  );
  const [errorMessage, setErrorMessage] = useState<string>(
    DEFAULT_ERROR_MESSAGE
  );

  useEffect(() => {
    if (localStorage.getItem("config_title")) {
      setTitle(localStorage.getItem("config_title")!);
    }
    if (localStorage.getItem("config_message")) {
      setMessage(localStorage.getItem("config_message")!);
    }
    if (localStorage.getItem("config_buttonText")) {
      setButtonText(localStorage.getItem("config_buttonText")!);
    }
    if (localStorage.getItem("config_successMessage")) {
      setSuccessMessage(localStorage.getItem("config_successMessage")!);
    }
    if (localStorage.getItem("config_errorMessage")) {
      setErrorMessage(localStorage.getItem("config_errorMessage")!);
    }
  }, []);

  function changeTitle(title: string) {
    localStorage.setItem("config_title", title);
    setTitle(title);
  }

  function changeMessage(message: string) {
    localStorage.setItem("config_message", message);
    setMessage(message);
  }

  function changeButtonText(buttonText: string) {
    localStorage.setItem("config_buttonText", buttonText);
    setButtonText(buttonText);
  }

  function changeSuccessMessage(successMessage: string) {
    localStorage.setItem("config_successMessage", successMessage);
    setSuccessMessage(message);
  }

  function changeErrorMessage(errorMessage: string) {
    localStorage.setItem("config_errorMessage", errorMessage);
    setErrorMessage(message);
  }

  function resetToDefault() {
    setTitle(DEFAULT_TITLE);
    localStorage.removeItem("config_title");

    setMessage(DEFAULT_MESSAGE);
    localStorage.removeItem("config_message");

    setButtonText(DEFAULT_BUTTON_TEXT);
    localStorage.removeItem("config_buttonText");

    setSuccessMessage(DEFAULT_SUCCESS_MESSAGE);
    localStorage.removeItem("config_successMessage");

    setErrorMessage(DEFAULT_ERROR_MESSAGE);
    localStorage.removeItem("config_errorMessage");
  }

  const contextValue: ConfigContextObj = {
    title,
    message,
    buttonText,
    successMessage,
    errorMessage,
    changeTitle,
    changeMessage,
    changeButtonText,
    changeSuccessMessage,
    changeErrorMessage,
    resetToDefault,
  };

  return (
    <ConfigContext.Provider value={contextValue}>
      {props.children}
    </ConfigContext.Provider>
  );
}

export default ConfigContextProvider;
