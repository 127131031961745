import React, { PropsWithChildren, useContext } from "react";

import { variables } from "@liveperson-design-system/tokens";
import { LPSDKContext } from "../../../store/lpsdk-context";

import classes from "./Card.module.scss";

function Card(props: PropsWithChildren<{}>) {
  const LPDSDKCtx = useContext(LPSDKContext);

  return (
    <div
      className={classes.card}
      style={{
        boxShadow:
          LPDSDKCtx.theme === "light"
            ? variables.shadow.shadowCard.lightHover
            : variables.shadow.shadowCard.darkHover,
      }}
    >
      {props.children}
    </div>
  );
}

export default Card;
