import React, { PropsWithChildren, useContext } from "react";
import { variables } from "@liveperson-design-system/tokens";

import { LPSDKContext } from "../../../store/lpsdk-context";

import classes from "./BottomBar.module.css";

function BottomBar(props: PropsWithChildren<{}>) {
  const LPSDKCtx = useContext(LPSDKContext);
  return (
    <div
      className={classes["bottom-bar"]}
      style={{
        backgroundColor:
          LPSDKCtx.theme === "light"
            ? variables.colors.navy.lightest
            : variables.colors.navy.darker,
      }}
    >
      {props.children}
    </div>
  );
}

export default BottomBar;
