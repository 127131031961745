import React, { useCallback, useEffect, useState } from "react";

declare type Theme = "dark" | "light";

type LPSDKContextObj = {
  theme: Theme;
  account: Number;
  conversationId: string;
};

export const LPSDKContext = React.createContext<LPSDKContextObj>({
  theme: "light",
  account: 0,
  conversationId: "",
});

function LPSDKContextProvider(props: { children: any }) {
  const [theme, setTheme] = useState<Theme>("light");
  const [account, setAccount] = useState<Number>(0);
  const [conversationId, setConversationId] = useState<string>("");

  const changeTheme = useCallback((data: { key: string; newValue: Theme }) => {
    setTheme(data.newValue);
  }, []);

  const changeAccount = useCallback(
    (data: { key: string; newValue: string }) => {
      setAccount(Number(data.newValue));
    },
    []
  );

  const changeConversationId = useCallback(
    (data: { key: string; newValue: string }) => {
      setConversationId(data.newValue);
    },
    []
  );

  useEffect(() => {
    console.log("Initializing AgentSDK...");
    lpTag.agentSDK.init();
    lpTag.agentSDK.bind("applicationInfo.theme", changeTheme);
    lpTag.agentSDK.bind("chatInfo.accountId", changeAccount);
    lpTag.agentSDK.bind("chatInfo.rtSessionId", changeConversationId);

    return () => {
      lpTag.agentSDK.unbind("applicationInfo.theme", changeTheme);
      lpTag.agentSDK.unbind("chatInfo.accountId", changeAccount);
      lpTag.agentSDK.unbind("chatInfo.rtSessionId", changeConversationId);
    };
  }, [changeTheme, changeAccount, changeConversationId]);

  const contextValue: LPSDKContextObj = {
    theme,
    account,
    conversationId,
  };

  return (
    <LPSDKContext.Provider value={contextValue}>
      {props.children}
    </LPSDKContext.Provider>
  );
}

export default LPSDKContextProvider;
