import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
import LPSDKContextProvider from "./store/lpsdk-context";
import ConfigContextProvider from "./store/config-context";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <ConfigContextProvider>
      <LPSDKContextProvider>
        <App />
      </LPSDKContextProvider>
    </ConfigContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
