import {
  LpInputLabel,
  LpTextInput,
  LpInputHelper,
  LpButton,
  LpTextarea,
} from "@liveperson-design-system/react-components";
import React, {
  useContext,
  useRef,
  useState,
  MouseEvent,
  FormEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../store/config-context";
import { LPSDKContext } from "../../store/lpsdk-context";
import Card from "../layout/Card/Card";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import classes from "./Config.module.css";

function Config() {
  const LPSDKCtx = useContext(LPSDKContext);
  const ConfigCtx = useContext(ConfigContext);

  const titleInputRef = useRef<HTMLLpTextInputElement>(null);
  const [titleValue, setTitleValue] = useState<string>(ConfigCtx.title);
  const messageInputRef = useRef<HTMLLpTextareaElement>(null);
  const [messageValue, setMessageValue] = useState<string>(ConfigCtx.message);
  const buttonTextInputRef = useRef<HTMLLpTextInputElement>(null);
  const [buttonTextValue, setButtonTextValue] = useState<string>(
    ConfigCtx.buttonText
  );
  const successMessageInputRef = useRef<HTMLLpTextInputElement>(null);
  const [successMessageValue, setSuccessMessageValue] = useState<string>(
    ConfigCtx.successMessage
  );
  const errorMessageInputRef = useRef<HTMLLpTextInputElement>(null);
  const [errorMessageValue, setErrorMessageValue] = useState<string>(
    ConfigCtx.errorMessage
  );

  const [titleError, setTitleError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<boolean>(false);
  const [buttonTextError, setButtonTextError] = useState<boolean>(false);
  const [successMessageError, setSuccessMessageError] =
    useState<boolean>(false);
  const [errorMessageError, setErrorMessageError] = useState<boolean>(false);

  const navigate = useNavigate();

  function saveHandler(event: MouseEvent<HTMLLpButtonElement>) {
    event.preventDefault();
    let error = false;

    setTitleValue(titleInputRef.current?.value!);
    if (titleInputRef.current?.value === "") {
      setTitleError(true);
      error = true;
    } else {
      setTitleError(false);
    }

    setMessageValue(messageInputRef.current?.value!);
    if (messageInputRef.current?.value === "") {
      setMessageError(true);
      error = true;
    } else {
      setMessageError(false);
    }

    setButtonTextValue(buttonTextInputRef.current?.value!);
    if (
      buttonTextInputRef.current?.value === "" ||
      (buttonTextInputRef.current?.value !== "" &&
        buttonTextInputRef.current?.value.length! > 20)
    ) {
      setButtonTextError(true);
      error = true;
    } else {
      setButtonTextError(false);
    }

    setSuccessMessageValue(successMessageInputRef.current?.value!);
    if (successMessageInputRef.current?.value === "") {
      setSuccessMessageError(true);
      error = true;
    } else {
      setSuccessMessageError(false);
    }

    setErrorMessageValue(errorMessageInputRef.current?.value!);
    if (errorMessageInputRef.current?.value === "") {
      setErrorMessageError(true);
      error = true;
    } else {
      setErrorMessageError(false);
    }

    if (error) {
      return;
    } else {
      if (titleInputRef.current?.value !== ConfigCtx.title) {
        ConfigCtx.changeTitle(titleInputRef.current?.value!);
      }

      if (messageInputRef.current?.value !== ConfigCtx.message) {
        ConfigCtx.changeMessage(messageInputRef.current?.value!);
      }

      if (buttonTextInputRef.current?.value !== ConfigCtx.buttonText) {
        ConfigCtx.changeButtonText(buttonTextInputRef.current?.value!);
      }

      if (successMessageInputRef.current?.value !== ConfigCtx.successMessage) {
        ConfigCtx.changeSuccessMessage(successMessageInputRef.current?.value!);
      }

      if (errorMessageInputRef.current?.value !== ConfigCtx.errorMessage) {
        ConfigCtx.changeErrorMessage(errorMessageInputRef.current?.value!);
      }

      navigate("/");
    }
  }

  function resetToDefaultsHandler(event: MouseEvent<HTMLLpButtonElement>) {
    event.preventDefault();
    ConfigCtx.resetToDefault();
    navigate("/");
  }

  function goBackHandler(event: MouseEvent<HTMLLpButtonElement>) {
    event.preventDefault();
    navigate("/");
  }

  return (
    <Card>
      <form className={classes["main-form"]}>
        <Tabs>
          <TabList>
            <Tab>Payment invite</Tab>
            <Tab>Widget</Tab>
          </TabList>
          <TabPanel>
            <div>
              <LpInputLabel theme={LPSDKCtx.theme}>Message title</LpInputLabel>
              <LpTextInput
                theme={LPSDKCtx.theme}
                ref={titleInputRef}
                inputMode="text"
                value={titleValue}
                error={titleError}
              />
              {titleError && (
                <LpInputHelper error theme={LPSDKCtx.theme}>
                  You need to provide a title for the message
                </LpInputHelper>
              )}
            </div>
            <div>
              <LpInputLabel theme={LPSDKCtx.theme}>Message body</LpInputLabel>
              <LpTextarea
                theme={LPSDKCtx.theme}
                ref={messageInputRef}
                inputMode="text"
                value={messageValue}
                error={messageError}
              />
              {messageError && (
                <LpInputHelper error theme={LPSDKCtx.theme}>
                  You need to provide a message body
                </LpInputHelper>
              )}
            </div>
            <div>
              <LpInputLabel theme={LPSDKCtx.theme}>Button text</LpInputLabel>
              <LpTextInput
                theme={LPSDKCtx.theme}
                ref={buttonTextInputRef}
                inputMode="text"
                value={buttonTextValue}
                error={buttonTextError}
              />
              {buttonTextError && (
                <LpInputHelper error theme={LPSDKCtx.theme}>
                  You need to provide a button text and it needs to be less than
                  20 characters
                </LpInputHelper>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div>
              <LpInputLabel theme={LPSDKCtx.theme}>
                Widget success message
              </LpInputLabel>
              <LpTextInput
                theme={LPSDKCtx.theme}
                ref={successMessageInputRef}
                inputMode="text"
                value={successMessageValue}
                error={successMessageError}
              />
              {buttonTextError && (
                <LpInputHelper error theme={LPSDKCtx.theme}>
                  You need to provide a success message
                </LpInputHelper>
              )}
            </div>
            <div>
              <LpInputLabel theme={LPSDKCtx.theme}>
                Widget error message
              </LpInputLabel>
              <LpTextInput
                theme={LPSDKCtx.theme}
                ref={errorMessageInputRef}
                inputMode="text"
                value={errorMessageValue}
                error={errorMessageError}
              />
              {buttonTextError && (
                <LpInputHelper error theme={LPSDKCtx.theme}>
                  You need to provide an error message
                </LpInputHelper>
              )}
            </div>
          </TabPanel>
        </Tabs>
        <LpButton
          theme={LPSDKCtx.theme}
          onClick={saveHandler}
          style={{ marginTop: "20px" }}
          variant="primary"
        >
          Save configurations
        </LpButton>
        <LpButton
          theme={LPSDKCtx.theme}
          onClick={resetToDefaultsHandler}
          style={{ marginTop: "5px" }}
          variant="destructive"
        >
          Reset to defaults
        </LpButton>
        <LpButton
          theme={LPSDKCtx.theme}
          onClick={goBackHandler}
          style={{ marginTop: "5px" }}
          variant="secondary"
        >
          Cancel update
        </LpButton>
      </form>
    </Card>
  );
}

export default Config;
