import React, { PropsWithChildren, useContext } from "react";
import { variables } from "@liveperson-design-system/tokens";

import classes from "./TitleBar.module.scss";
import { LPSDKContext } from "../../../store/lpsdk-context";

function TitleBar(props: PropsWithChildren<{}>) {
  const LPSDKCtx = useContext(LPSDKContext);

  return (
    <div
      className={classes["top-bar"]}
      style={{
        backgroundColor:
          LPSDKCtx.theme === "light"
            ? variables.colors.navy.lightest
            : variables.colors.navy.darker,
      }}
    >
      <h1>{props.children}</h1>
    </div>
  );
}

export default TitleBar;
